import type { z } from "zod"

import type { UnlikelyOrderFulfillmentStatus } from "@unlikelystudio/commerce-connector"

import { ALERT_PRESETS, type AlertPreset } from "~/lib/constants/alerts"
import { fulfillmentStatus } from "~/lib/shopify/constants/fulfillment-statuses"
import { setDictionarySchema } from "~/providers/I18nProvider/utils/set-dictionnary-schema"
import { isObject } from "~/utils/is-object"

type KeyStartWith<T extends string> = readonly `${T}${string}`[]

const PRODUCT_KEYS = [
  "product_details_description",
  "product_details_composition_entretien",
  "product_details_confection_savoir_faire",
  "product_details_livraison_retours",
  "product_details_sizes",
  "product_details_confort",
  "product_details_amount",
  "product_price_from",
  "product_add_to_cart",
  "product_added_to_cart",
  "product_out_of_stock",
  "product_in_stock",
  "product_select_sizes",
  "product_bundle_variant_out_of_stock",
  "product_delivery_estimations",
  "product_delivery_estimations_today",
  "product_out_of_stock_select",
  "product_complete_your_order",
] as const satisfies KeyStartWith<"product_">

const ARIA_KEYS = [
  "aria_breadcrumb",
  "aria_arrow_prev",
  "aria_arrow_next",
  "aria_nav_mobile_accordion_open",
  "aria_nav_mobile_accordion_close",
  "aria_newsletter",
  "aria_size_chart_accordion_open",
  "aria_size_chart_accordion_close",
  "aria_chatbot_open",
  "aria_chatbot_close",
  "aria_materials_close_panel",
  "aria_logo_alt",
  "aria_legals_select",
  "aria_usp_banner_close",
  "aria_popin_close",
  "aria_cart_close",
  "aria_cart_open",
  "aria_panel_embroidery_close",
  "aria_panel_size_close",
  "aria_cart_remove_item",
  "aria_panel_gift_back",
  "aria_collections_layout_switch",
  "aria_color_switch",
  "aria_mobile_nav_open",
  "aria_mobile_nav_close",
  "aria_panel_close",
  "aria_order_accordion_open",
  "aria_order_accordion_close",
  "aria_navigation_account",
  "aria_remove_search",
  "aria_video_progress_label",
  "aria_video_volume_label",
] as const satisfies KeyStartWith<"aria_">

const SEARCH_KEYS = [
  "search_placeholder",
  "search_result_label",
  "search_no_result",
  "search_suggest",
] as const satisfies KeyStartWith<"search_">

export const FORM_KEYS = {
  email: "form_email",
  toggle_checkbox: "form_toggle_checkbox",
  newsletter_success: "form_newsletter_success",
  message_to: "form_message_to",
  message_from: "form_message_from",
  message: "form_message",
  cta_submit: "form_cta_submit",
  cta_back_home: "form_cta_back_home",
} as const satisfies Record<string, KeyStartWith<"form_">[number]>

export const ACCOUNT_KEYS = {
  add_address: "account_add_address",
  add_first_address: "account_add_first_address",
  already_have_account: "account_already_have_account",
  billing_address: "account_billing_address",
  change_password: "account_change_password",
  delete_address: "account_delete_address",
  dont_have_account: "account_dont_have_account",
  download_invoice: "account_download_invoice",
  edit_address: "account_edit_address",
  informations_page: "account_informations_page",
  log_in: "account_log_in",
  logout: "account_logout",
  make_default_address: "account_make_default_address",
  order_date: "account_order_date",
  order_number: "account_order_number",
  order_price: "account_order_price",
  order_state: "account_order_state",
  navigation: "account_navigation",
  quantity: "account_quantity",
  register: "account_register",
  reset_password: "account_reset_password",
  shipping: "account_shipping",
  shipping_address: "account_shipping_address",
  subtotal: "account_subtotal",
  total: "account_total",
  discount: "account_discount",
  tracking: "account_tracking",
  welcome: "account_welcome",
  your_order: "account_your_order",
  province: "account_province",
  accepts_marketing: "account_accepts_marketing",
} as const satisfies Record<string, KeyStartWith<"account_">[number]>

export const ERROR_KEYS = {
  email: "error_email",
  required: "error_required",
  max: "error_max",
  at_least_one_embroidery: "error_at_least_one_embroidery",
  character_not_allowed: "error_character_not_allowed",
  at_least_one_product: "error_at_least_one_product",
} as const satisfies Record<string, KeyStartWith<"error_">[number]>

export const ALERT_KEYS = {
  error_title: "alert_error_title",
  success_title: "alert_success_title",
  info_title: "alert_info_title",
  ...(ALERT_PRESETS.reduce((acc, alert) => {
    acc[alert] = `alert_${alert}`
    return acc
  }, {} as Record<AlertPreset, string>) as { [k in AlertPreset]: `alert_${k}` }),
} as const satisfies Record<string, KeyStartWith<"alert_">[number]>

const PRODUCT_CARD_KEYS = [
  "product_card_color",
  "product_card_colors",
  "product_card_unavailable",
  "product_card_add",
] as const satisfies KeyStartWith<"product_card_">
const COLLECTION_KEYS = [
  "collection_material_suptitle",
  "collection_default_suptitle",
] as const satisfies KeyStartWith<"collection_">
const SIZE_CHART_KEYS = [
  "size_chart_type",
  "size_chart_back",
  "size_chart_trigger",
] as const satisfies KeyStartWith<"size_chart_">
const LANG_SWITCHER_KEYS = [] as const satisfies KeyStartWith<"lang_switcher_">
const MATERIALS_KEYS = ["materials_title", "materials_discover"] as const satisfies KeyStartWith<"materials_">
const SHOP_THE_LOOK_KEYS = [
  "shop_the_look_title",
  "shop_the_look_cta",
  "shop_the_look_product_card_out_of_stock",
] as const satisfies KeyStartWith<"shop_the_look_">
const EMBROIDERY_KEYS = [
  "embroidery_trigger",
  "embroidery_font_title",
  "embroidery_color_title",
  "embroidery_small_title",
  "embroidery_long_title",
  "embroidery_constraint",
  "embroidery_nota_bene_text",
  "embroidery",
  "embroidery_summary_title",
  "embroidery_thread",
] as const satisfies KeyStartWith<"embroidery">
const CART_KEYS = [
  "cart_title",
  "cart_free_delivery_gauge_actived",
  "cart_free_delivery_gauge_unactived",
  "cart_estimated_delivery_cost",
  "cart_estimated_delivery_cost_number",
  "cart_articles",
  "cart_single_article",
  "cart_gift_panel_maximum_characters",
  "cart_ignore_color_option",
  "cart_validation_label",
  "cart_view",
] as const
const GENERAL_KEYS = [
  "general_homepage",
  "general_load_more",
  "general_all",
  "general_back",
  "general_blog_page",
] as const
const MARKET_KEYS = ["market_title", "market_country_label", "market_language_label", "market_submit_label"] as const
const BADGE_KEYS = [
  "badge_out_of_stock",
  "badge_new",
  "badge_main_address",
  ...fulfillmentStatus.map(
    (status) => `badge_${status.toLowerCase() as Lowercase<UnlikelyOrderFulfillmentStatus>}` as const
  ),
] as const satisfies KeyStartWith<"badge_">

export const dictionaryKeys = [
  GENERAL_KEYS,
  ARIA_KEYS,
  PRODUCT_CARD_KEYS,
  PRODUCT_KEYS,
  COLLECTION_KEYS,
  FORM_KEYS,
  ERROR_KEYS,
  ALERT_KEYS,
  SIZE_CHART_KEYS,
  LANG_SWITCHER_KEYS,
  MATERIALS_KEYS,
  CART_KEYS,
  EMBROIDERY_KEYS,
  MARKET_KEYS,
  SEARCH_KEYS,
  ACCOUNT_KEYS,
  BADGE_KEYS,
  SHOP_THE_LOOK_KEYS,
]
  .map((key) => (isObject(key) ? Object.values(key) : key))
  .flat()

export const dictionarySchema = setDictionarySchema(dictionaryKeys)

export type Dictionary = z.infer<typeof dictionarySchema>
