import type { UnlikelyCart } from "@unlikelystudio/commerce-connector"
import {
  useAddCartLines,
  useRemoveCartLines,
  useUpdateCartAttributes,
  useUpdateCartLines,
} from "@unlikelystudio/react-ecommerce-hooks"

import type { Nullish } from "~/@types/generics"
import { useLocale } from "~/lib/i18n/hooks/useLocale"
import {
  CHECKOUT_CUSTOM_ATTRIBUTES,
  CHECKOUT_ORIGIN_VALUE,
  getProductCustomAttributesValue,
} from "~/lib/shopify/constants"
import useLang from "~/hooks/useLang"
import useMarket from "~/hooks/useMarket"

type MutationAddOnSuccess = Parameters<NonNullable<NonNullable<Parameters<typeof useAddCartLines>[0]>["onSuccess"]>>
type MutationUpdateOnSuccess = Parameters<
  NonNullable<NonNullable<Parameters<typeof useUpdateCartLines>[0]>["onSuccess"]>
>
type MutationRemoveOnSuccess = Parameters<
  NonNullable<NonNullable<Parameters<typeof useRemoveCartLines>[0]>["onSuccess"]>
>

type MutationAddOnError = Parameters<NonNullable<NonNullable<Parameters<typeof useAddCartLines>[0]>["onError"]>>
type MutationUpdateOnError = Parameters<NonNullable<NonNullable<Parameters<typeof useUpdateCartLines>[0]>["onError"]>>
type MutationRemoveOnError = Parameters<NonNullable<NonNullable<Parameters<typeof useRemoveCartLines>[0]>["onError"]>>

type MutationRemoveOnSettled = Parameters<
  NonNullable<NonNullable<Parameters<typeof useRemoveCartLines>[0]>["onSettled"]>
>

type UseAddToCartActions = {
  onSuccessAddLines?: (...params: MutationAddOnSuccess) => void
  onSuccessUpdateLines?: (...params: MutationUpdateOnSuccess) => void
  onSuccessRemoveLines?: (...params: MutationRemoveOnSuccess) => void
  onErrorAddLines?: (...params: MutationAddOnError) => void
  onErrorUpdateLines?: (...params: MutationUpdateOnError) => void
  onErrorRemoveLines?: (...params: MutationRemoveOnError) => void
  onSettledRemoveLines?: (...params: MutationRemoveOnSettled) => void
}

export function useCartActions(params?: UseAddToCartActions) {
  const {
    onSuccessAddLines,
    onSuccessUpdateLines,
    onSuccessRemoveLines,
    onErrorAddLines,
    onErrorUpdateLines,
    onErrorRemoveLines,
    onSettledRemoveLines,
  } = params ?? {}

  const lang = useLang()
  const market = useMarket()
  const locale = useLocale()

  const { mutate: updateAttributes } = useUpdateCartAttributes()

  async function onActionSuccess({ cart }: { cart: Nullish<UnlikelyCart> }) {
    if (!cart) {
      return
    }

    // Getting the existing cart attributes to overload them if needed
    const currentCartAttributes =
      cart?.attributes?.flatMap((attribute) => {
        if (attribute.value === null) {
          return []
        }
        return {
          key: attribute.key,
          value: attribute.value,
        }
      }) ?? []

    // Getting the cart lines and getting their tracking data
    const trackingDataAttributes =
      cart?.lines
        ?.map((lineItem) => {
          const lineItemTrackingData = getProductCustomAttributesValue(lineItem.attributes, "TRACKING_DATA")

          if (lineItemTrackingData) {
            return JSON.parse(lineItemTrackingData)
          }
          return null
        })
        ?.filter(Boolean) ?? []

    updateAttributes({
      cartId: cart.id,
      attributes: [
        ...currentCartAttributes,
        {
          key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_PRODUCT_TRACKING_DATA,
          value: JSON.stringify(trackingDataAttributes),
        },
        /* Adding custom attributes to the cart. */
        {
          key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_ORIGIN,
          // Cart origin -> as headless
          value: CHECKOUT_ORIGIN_VALUE,
        },
        /* Adding a custom attribute cartLang to the cart. */
        {
          key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_LANG,
          value: lang,
        },
        /* Adding a custom attribute cartLocale to the cart. */
        {
          key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_LOCALE,
          value: locale,
        },
        /* Adding a custom attribute cartMarket to the cart. */
        {
          key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_MARKET,
          value: market,
        },
      ],
    })
  }

  /* The `const addLinesToCartAction` is a function that is using the `useAddCartLines` hook. This
  function is responsible for adding lines to the cart. */
  const addLinesToCartAction = useAddCartLines({
    onSuccess: (...params) => {
      onActionSuccess(params[0])

      onSuccessAddLines?.(...params)
    },
    onError: onErrorAddLines,
  })

  /* The `const removeLinesFromCartAction` is a constant that is assigned the result of calling the
  `useRemoveCartLines` hook. This hook is used to remove lines from the cart. */
  const removeLinesFromCartAction = useRemoveCartLines({
    onSuccess: (...params) => {
      onActionSuccess(params[0])

      onSuccessRemoveLines?.(...params)
    },
    onError: onErrorRemoveLines,
    onSettled: onSettledRemoveLines,
  })

  /* The `const updateCartLinesAction` is a constant that is assigned the result of calling the
  `useUpdateCartLines` hook. This hook is used to update the lines in the cart. */
  const updateCartLinesAction = useUpdateCartLines({
    onSuccess: (...params) => {
      onActionSuccess(params[0])

      onSuccessUpdateLines?.(...params)
    },
    onError: onErrorUpdateLines,
  })

  return {
    addLinesToCartAction,
    removeLinesFromCartAction,
    updateCartLinesAction,
    onActionSuccess,
  }
}
