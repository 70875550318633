import { useState } from "react"
import { useForm, type FieldValues, type UseFormProps } from "react-hook-form"

import { RECAPTCHA_PARAMS } from "~/lib/api/constants"
import { requestNextApiEndpoint } from "~/lib/api/utils/request-endpoint"
import { ALERT_CODES } from "~/lib/constants"
import { useAlerts } from "~/hooks/useAlerts"
import { useGoogleReCaptcha } from "~/providers/RecaptchaProvider"
import { logger } from "~/utils/logger"
import type { VerifiyResponse } from "~/app/api/google/verify-recaptcha/route"

export type TAction = "newsletter"

export const useFormWithRecaptcha = <T extends FieldValues>(
  onSubmit: (values: T) => void,
  formOptions?: UseFormProps<T>,
  action?: TAction
) => {
  const [isLoading, setIsLoading] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const triggerAlert = useAlerts()
  const form = useForm<T>({
    ...formOptions,
  })

  const submitHandler = form.handleSubmit(async (data) => {
    let handleCaptcha
    if (!action) return onSubmit(data)

    try {
      setIsLoading(true)
      if (!executeRecaptcha) {
        triggerAlert(ALERT_CODES.GOOGLE_RECAPTCHA_ERROR)
        setIsLoading(false)
        return
      }

      const token = await executeRecaptcha(action)

      handleCaptcha = (await requestNextApiEndpoint(RECAPTCHA_PARAMS, {
        payload: { token, action },
      })) as Partial<VerifiyResponse>

      if (!handleCaptcha?.success) {
        triggerAlert(ALERT_CODES.GOOGLE_RECAPTCHA_ERROR)
        setIsLoading(false)
        return
      }

      onSubmit(data)
    } catch (err) {
      logger.error("[Recaptcha ERROR]: ", err)
      triggerAlert(ALERT_CODES.GOOGLE_RECAPTCHA_ERROR)
    } finally {
      setIsLoading(false)
    }
  })

  return { form, onSubmit: submitHandler, isLoading }
}
