import { getProductCustomAttributesValue } from "~/lib/shopify/constants"
import { useCartActions } from "~/hooks/cart/useCartActions"
import type { CartLineItemPayload } from "~/providers/GTMTrackingProvider/constants"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"
import { createCartUrl } from "~/utils/create-cart-url"

export function useAddToCart(props?: Parameters<typeof useCartActions>[0]) {
  const { onSuccessAddLines, ...mutationOptions } = props ?? {}
  const { sendEvent, sendResetEvent } = useTracking()

  const { addLinesToCartAction } = useCartActions({
    ...mutationOptions,
    onSuccessAddLines: (data, variables, error) => {
      onSuccessAddLines?.(data, variables, error)

      const cart = data?.cart
      if (!cart) {
        return
      }

      sendResetEvent()
      sendEvent("add_to_cart", {
        ecommerce: {
          value: cart?.cost?.totalAmount?.amount,
          cart_url: createCartUrl(),
          items: variables.flatMap((variable) => {
            const trackingData = getProductCustomAttributesValue(variable.attributes, "TRACKING_DATA")
            if (trackingData) {
              return JSON.parse(trackingData) as CartLineItemPayload
            }
            return []
          }),
          items_in_cart: cart.lines.flatMap((variable) => {
            const trackingData = getProductCustomAttributesValue(variable.attributes, "TRACKING_DATA")
            if (trackingData) {
              return JSON.parse(trackingData) as CartLineItemPayload
            }
            return []
          }),
        },
      })
    },
  })

  return addLinesToCartAction
}
