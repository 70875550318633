import { useParams } from "next/navigation"

import { useAlertContext } from "@unlikelystudio/react-abstract-components"
import { formDictionary } from "@unlikelystudio/react-ecommerce-hooks"

import {
  ALERT_CODES,
  type AlertCodesValues,
  type FormDictionnaryKey,
  type FormDictionnaryLang,
} from "~/lib/constants/alerts"
import { getI18nKey } from "~/lib/i18n/utils/get-i18n"
import { ALERT_KEYS } from "~/providers/I18nProvider/constants"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

export const useAlerts = () => {
  const { triggerAlert } = useAlertContext()
  const t = useTranslate()

  const params = useParams()
  const language = getI18nKey(params.locale, "lang")

  const getPreset = (preset: AlertCodesValues = ALERT_CODES.SERVER_ERROR) => {
    switch (preset) {
      case ALERT_CODES.ENTITY_SAVED:
      case ALERT_CODES.BACK_IN_STOCK_SUCCESS:
      case ALERT_CODES.NEWSLETTER_SUBSCRIBE:
      case ALERT_CODES.CUSTOMER_ADDRESS_UPDATED:
      case ALERT_CODES.CUSTOMER_ADDRESS_CREATED:
      case ALERT_CODES.EMAIL_SENT:
        return {
          title: t(ALERT_KEYS["success_title"]),
          message: t(ALERT_KEYS[preset]),
          type: "success",
        }
      default:
        return {
          title: t(ALERT_KEYS["error_title"]),
          message: t(ALERT_KEYS[preset], {
            _: formDictionary?.[language as FormDictionnaryLang]?.[preset as FormDictionnaryKey] ?? preset,
          }),
          type: "error",
        }
    }
  }

  return (preset?: AlertCodesValues) => {
    triggerAlert(getPreset(preset))
  }
}
