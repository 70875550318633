"use client"

import React, { useState, type ComponentProps, type PropsWithChildren } from "react"
import clsx from "clsx"
import { AnimatePresence, m } from "framer-motion"

import { FormError, FormLabel } from "~/components/ui/Form"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

export type InputProps = PropsWithChildren<
  Partial<{
    inputClassName?: string
    inputWrapperClassName?: string
    labelClassName?: string
    errorBackgroundColor?: Extract<Sprinkles["bgColor"], "white" | "grey-light-bg">
  }> &
    ComponentProps<"input"> &
    css.InputsVariants
>

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      inputClassName,
      inputWrapperClassName,
      children,
      variant = "default",
      textStyle = "default",
      withIcon = false,
      errorBackgroundColor = "grey-light-bg",
      labelClassName,
      ...props
    },
    ref
  ) => {
    const [onFocus, setOnFocus] = useState(false)

    const AbstractInputComponent = (
      <input
        ref={ref}
        className={clsx(css.Input({ variant, textStyle, withIcon }), inputClassName)}
        {...props}
        onFocus={(e) => {
          setOnFocus(true)
          props.onFocus?.(e)
        }}
        onBlur={(e) => {
          setOnFocus(false)
          props.onBlur?.(e)
        }}
      />
    )

    return (
      <div className={clsx(css.InputContainer, className)}>
        {children && (
          <div className={inputWrapperClassName}>
            {AbstractInputComponent}
            {children}
          </div>
        )}
        {!children && AbstractInputComponent}

        <AnimatePresence>
          {(onFocus || props.value) && props.placeholder && variant === "default" && (
            <m.span
              className={clsx(
                css.LabelTop,
                labelClassName,
                sprinkles({
                  position: "absolute",
                  bgColor: errorBackgroundColor,
                  marginLeft: 12,
                  pX: 8,
                })
              )}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <FormLabel>{props.placeholder}</FormLabel>
            </m.span>
          )}
        </AnimatePresence>

        {variant === "default" && (
          <FormError
            className={clsx(
              css.ErrorMessage,
              sprinkles({
                position: "absolute",
                bgColor: errorBackgroundColor,
                marginLeft: 12,
                pX: 8,
              })
            )}
          />
        )}
      </div>
    )
  }
)

export default Input
