import { CART_OPEN_VALUE, CART_PARAM } from "~/components/ui/Product/ProductHeader/_data/constants"
import getAlternateUrl from "~/utils/alternate-url"
import { applySearchParams } from "~/utils/apply-searchparams"

export function createCartUrl() {
  const searchParams = new URLSearchParams()
  searchParams.set(CART_PARAM, CART_OPEN_VALUE)

  return getAlternateUrl(applySearchParams("", searchParams))
}
