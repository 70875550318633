"use client"

import { zodResolver } from "@hookform/resolvers/zod"
import clsx from "clsx"
import { z } from "zod"

import { useFormWithRecaptcha, type TAction } from "~/hooks/useFormWithRecaptcha"
import Checkbox from "~/components/ui/Checkbox"
import { Form, FormControl, FormField, FormItem, FormLabel } from "~/components/ui/Form"
import Input from "~/components/ui/Input"
import { Link } from "~/components/ui/Link"
import type { TNewsletterForm } from "~/components/ui/NewsletterForm/_data/serializer"
import RoundedCta from "~/components/ui/RoundedCta"
import { Stack } from "~/components/abstracts/Stack"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type TEmailAndPrivacyFormData = z.infer<ReturnType<typeof getSchema>>

type EmailAndPrivacyFormProps = {
  onSubmit: (values: TEmailAndPrivacyFormData) => void
  isLoading: boolean
  formData: NonNullable<TNewsletterForm>
  onEmailChange?: () => void
  action?: TAction
}

export function EmailAndPrivacyForm({
  isLoading,
  formData,
  onEmailChange,
  onSubmit,
  action,
}: EmailAndPrivacyFormProps) {
  const t = useTranslate()

  const schema = getSchema(t, !formData.showPrivacyPolicyCheckbox)

  const {
    form,
    onSubmit: onFormSubmit,
    isLoading: isRecaptchaLoading,
  } = useFormWithRecaptcha<TEmailAndPrivacyFormData>(
    onSubmit,
    {
      resolver: zodResolver(schema),
    },
    action
  )

  return (
    <Form {...form}>
      <Stack asChild direction="column" sprinklesCss={{ position: "relative" }} gap={15}>
        <form onSubmit={onFormSubmit}>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    variant="default"
                    {...field}
                    withIcon
                    placeholder={t("form_email")}
                    value={field.value ?? ""}
                    onChange={(event) => {
                      onEmailChange?.()
                      field.onChange(event)
                    }}
                  >
                    <RoundedCta
                      className={css.buttonSubmit}
                      iconName="ArrowRight"
                      aria-label={t("aria_newsletter")}
                      htmlType="submit"
                      type="secondary"
                      opacityBorder={0}
                      displayIcon
                      isLoading={isLoading || isRecaptchaLoading}
                    />
                  </Input>
                </FormControl>
              </FormItem>
            )}
          />

          {formData.showPrivacyPolicyCheckbox ? (
            <FormField
              control={form.control}
              name="acceptsMarketing"
              render={({ field }) => (
                <FormItem className={clsx(sprinkles({ display: "inline-flex" }))}>
                  <FormLabel
                    className={clsx(sprinkles({ display: "flex", gap: 8, alignItems: "center", cursor: "pointer" }))}
                  >
                    <FormControl>
                      <Checkbox checked={field.value} onChange={field.onChange} />
                    </FormControl>
                    <p
                      className={text({
                        design: "riposte-14-11",
                        color: "blue-light-txt",
                      })}
                    >
                      {formData.privacyPolicyPrefix}&thinsp;
                      <Link
                        className={text({
                          design: "riposte-14-11",
                          color: "blue-deep",
                        })}
                        {...formData.linkToPrivacyPolicy}
                      />
                    </p>
                  </FormLabel>
                </FormItem>
              )}
            />
          ) : null}
        </form>
      </Stack>
    </Form>
  )
}

function getSchema(t: ReturnType<typeof useTranslate>, defaultAcceptsMarketing: boolean) {
  return z.object({
    email: z.string({ required_error: t("error_required") }).email({ message: t("error_email") }),
    acceptsMarketing: z.boolean().optional().default(defaultAcceptsMarketing),
  })
}
