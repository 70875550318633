import { encode } from "qss"

import { UnlikelyRequestHeaders } from "@unlikelystudio/commerce-connector"

import { QUERY_METHODS } from "~/lib/api/constants"
import type { EndpointSettings, GenerateQueryParams, QueryParams } from "~/lib/api/types"
import { getStorePublicCredentials } from "~/lib/shopify/client/public"
import { applySearchParams } from "~/utils/apply-searchparams"

async function generateQuery({
  action,
  payload,
  accessToken,
  queryParams = null,
  method,
  headers,
}: GenerateQueryParams) {
  const hasBody = method !== QUERY_METHODS.GET
  const shop = getStorePublicCredentials()

  const req = await fetch(applySearchParams(`/api/${action}/`, queryParams ? encode(queryParams) : null), {
    headers: {
      "Content-Type": "application/json",
      ...(headers ? headers : {}),
      ...(accessToken && {
        [UnlikelyRequestHeaders.AUTHORIZATION]: accessToken,
        [UnlikelyRequestHeaders.STORE_URL]: shop?.storeUrl,
        [UnlikelyRequestHeaders.STORE_TOKEN]: shop?.storefrontAccessToken,
      }),
    },
    method,
    ...(hasBody && { body: JSON.stringify(payload) }),
  })

  const res = await req.json()

  return res
}

export const requestNextApiEndpoint = async <TResponse extends unknown>(
  settings: EndpointSettings,
  params?: QueryParams
) => {
  return (await generateQuery({
    ...settings,
    ...(params ? params : {}),
  })) as Promise<TResponse>
}
