"use client"

import React, { type ComponentProps, type PropsWithChildren } from "react"
import clsx from "clsx"

import Icon from "~/components/abstracts/Icon"

import * as css from "./styles.css"

export type CheckboxProps = PropsWithChildren<{
  inputClassName?: string
  inputWrapperClassName?: string
}> &
  ComponentProps<"input">

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(({ className, inputClassName, ...props }, ref) => {
  return (
    <div className={className}>
      <input ref={ref} type="checkbox" {...props} className={clsx(css.Input, inputClassName)} />

      <span className={clsx(css.CheckMark)}>
        <span className={clsx(css.CheckMarkActive)}>{<Icon name="Tick" width={16} height={16} />}</span>
      </span>
    </div>
  )
})

export default Checkbox
