//@ts-strict
import { useState } from "react"

import { useIsomorphicLayoutEffect } from "@unlikelystudio/react-hooks"

import { breakpoints, type Screens } from "~/styles/variables/breakpoints"

type QueryDirection = "min" | "max"

interface useBreakpointOptions {
  direction?: QueryDirection
  ssr?: boolean
  fallback?: boolean
}

export default function useBreakpoint(breakpoint: keyof Screens, options: useBreakpointOptions = {}): boolean {
  const { direction = "max", ssr = true, fallback = true } = options

  const query = `(${direction}-width: ${breakpoints?.[breakpoint]}px)`

  const getMatches = () => {
    return window.matchMedia(query).matches
  }

  const [matches, setMatches] = useState<boolean>(ssr ? fallback : getMatches())

  function handleChange() {
    setMatches(getMatches())
  }

  useIsomorphicLayoutEffect(() => {
    const matchMedia = window.matchMedia(query)

    handleChange()

    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange)
    } else {
      matchMedia.addEventListener("change", handleChange)
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange)
      } else {
        matchMedia.removeEventListener("change", handleChange)
      }
    }
  }, [query])

  return matches
}
