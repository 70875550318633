import type { ObjectValues } from "~/@types/generics"

// Query Methods
export const QUERY_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
} as const

export const QUERY_METHODS_KEYS = Object.values(QUERY_METHODS)
export type QueryMethodsKeys = keyof typeof QUERY_METHODS

// Query routes
export const QUERY_ROUTES = {
  SHOPIFY_NEWSLETTER: "customers/newsletter",
  SHOPIFY_CUSTOMER_LOCALE: "customers/locale",
  SHOPIFY_CUSTOMER_METAFIELDS: "customers/metafields",
  KLAVIYO_BACK_IN_STOCK: "klaviyo/back-in-stock",
  KLAVIYO_ADDED_TO_CART: "klaviyo/added-to-cart",
  VERIFY_RECAPTCHA: "google/verify-recaptcha",
  SHOPIFY_MULTIPASS: "multipass",
} as const

export const QUERY_ROUTES_KEYS = Object.values(QUERY_ROUTES)
export type QueryRoutesKeys = ObjectValues<typeof QUERY_ROUTES_KEYS>

// Params

// Shopify
export const SHOPIFY_NEWSLETTER_PARAMS = {
  action: QUERY_ROUTES.SHOPIFY_NEWSLETTER,
  method: QUERY_METHODS.POST,
}

export const SHOPIFY_CUSTOMER_LOCALE_PARAMS = {
  action: QUERY_ROUTES.SHOPIFY_CUSTOMER_LOCALE,
  method: QUERY_METHODS.PUT,
}

export const SHOPIFY_CUSTOMER_METAFIELDS_PARAMS = {
  action: QUERY_ROUTES.SHOPIFY_CUSTOMER_METAFIELDS,
  method: QUERY_METHODS.PUT,
}

// Klaviyo
export const KLAVIYO_BACK_IN_STOCK_PARAMS = {
  action: QUERY_ROUTES.KLAVIYO_BACK_IN_STOCK,
  method: QUERY_METHODS.POST,
}

// Recaptcha
export const RECAPTCHA_PARAMS = {
  action: QUERY_ROUTES.VERIFY_RECAPTCHA,
  method: QUERY_METHODS.POST,
}

// Multipass
export const SHOPIFY_MULTIPASS_PARAMS = {
  action: QUERY_ROUTES.SHOPIFY_MULTIPASS,
  method: QUERY_METHODS.POST,
}
